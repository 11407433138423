<template>
  <Roles class="residential-roles" :value="items" />
</template>

<script>
import { mapState } from 'vuex'
import Roles from '@/components/ResidentialComponents/Roles'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return this.$path('item.project.roles.salesman') || []
    },
  },
  components: {
    Roles,
  },
}
</script>
