<template>
  <MapComponent class="residential-map" :value="$path('project.address', item)" />
</template>

<script>
import MapComponent from '@/components/ResidentialComponents/Map'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Residential', ['item']),
  },
  components: {
    MapComponent,
  },
}
</script>
