<template>
  <Loader :value="promises.fetch" theme="default" class="project-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'

// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/project'

// custom components
import ProjectComponents from '@/components/Project'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return { ...ProjectComponents }
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.project-page {
  &__banner {
    height: calc(100vh - var(--nav-height));
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    color: var(--white);

    &--darken {
      background: rgba(0, 0, 0, 0.25);
      width: 100%;
      height: 100%;
    }
  }

  &__layout {
  }

  &__blackbox {
    background: var(--black);

    padding: 0.625rem 1.25rem;
    margin-bottom: 1.25rem;
    text-align: center;
    width: fit-content;
    h1,
    h2 {
      margin-block-start: 0em;
      margin-block-end: 0em;
      line-height: 1.2;
      margin: 0;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 2.6rem;
    }
    p:first-of-type {
      margin-top: 1.25rem;
    }
    p {
      margin-block-start: 0em;
      margin-block-end: 0em;
      font-weight: 400;
      line-height: 1.5;
      font-size: 1rem;
    }
    p:last-of-type {
      margin-top: 1.25rem;
      margin-bottom: 1rem;
    }
  }

  &__content {
    line-height: 1.5;
  }

  &__button-layout {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    @media (max-width: 600px) {
      flex-direction: row !important;
    }
  }

  &__button {
    &--primary {
      span {
        a {
          font-size: 1.2rem;
          background-color: var(--primary);
          color: var(--black);
          &:hover {
            background-color: var(--primary-hover);
          }
        }
      }
    }

    &--secondary {
    }
  }
}
</style>
