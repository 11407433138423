<template>
  <div v-if="value.length" class="residentials">
    <div class="residentials__wrapper">
      <h5 class="residentials__header">Arealtabell</h5>
      <table class="residentials__table" v-if="properties.length">
        <thead>
          <tr>
            <th v-for="(property, index) in properties" :key="'value_th_' + index">
              <span v-if="getPropertyLabel(property)">
                {{ getPropertyLabel(property) }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e, idx) in value" :key="'tr_' + idx">
            <td v-for="(property, index) in properties" :key="'value__td' + index">
              <span v-if="getProperty(e, property)">
                {{ getProperty(e, property) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import Bus from '@/bus'

import Residentials from '@kvass/residentials/src/Component'
import mitt from 'mitt'

const eventBus = mitt()

export { eventBus }
export default {
  data() {
    return {
      filter: {},
      project: API.project,
    }
  },
  props: {
    value: Array,
  },
  computed: {
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  methods: {
    onClick(e) {
      eventBus.emit('click', e)
      if (e.redirectUrl) return (window.location.href = e.redirectUrl)
      this.$router.push({
        name: 'Residential',
        params: { id: e.id },
        query: this.$route.query,
      })
    },

    getFloorplan(item) {
      return this.$path('media.floorplan.0', item) || {}
    },
    getProperty(item, key) {
      let match = item.properties.find(p => p.key === key)
      if (!match) return

      return match.formatted
    },

    getPropertyLabel(key) {
      let match = this.value.filter(Boolean).find(i => i.properties.find(p => p.key === key))

      if (!match) return

      let property = match.properties.find(p => p.key === key)

      return property.label || this.$t(property.key)
    },

    getProperties(item) {
      return item.properties.filter(i => i.key === ('propertType' || 'btaM'))
    },
  },
  created() {
    Bus.on('residentials:filter', filter => (this.filter = filter))
  },
  components: {
    Residentials,
  },
}
</script>

<style lang="scss">
.residentials {
  background-color: var(--white);
  width: fit-content;
  margin: 0 auto;

  padding: 1.25rem;

  &__table {
    border-collapse: collapse;
    min-width: 100%;

    tbody {
      tr {
        line-height: 30px;

        border-bottom: 1px solid #d0d0d0;
        cursor: unset;
      }
    }

    td {
      line-height: 30px;
      padding-right: 15px;
      font-size: 1rem;
      padding-bottom: 1rem !important;
      text-align: left;
    }

    thead {
      th {
        font-weight: 400;
        border-bottom: 1px solid #d0d0d0;
        background-color: inherit;
        padding-right: 15px;
        font-size: 1rem;
        line-height: 30px;
        text-align: left;
      }
    }
  }

  &__scroller {
    background: none;
  }

  &__header {
    font-size: 1.25rem;
    text-align: center !important;
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  &-actions {
    margin-left: auto;
    width: max-content;

    &__item {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 10rem;
      border: 1px solid transparent;

      &:hover {
        border-color: var(--primary-contrast);
      }
    }
  }
}
</style>
