<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__content-bottom-line">
        <router-link
          :to="`/api/legal/privacy/project/${item.id}`"
          target="_blank"
          class="footer__content-link footer__content-link-left"
        >
          {{ $t('privacyPolicy') }}
        </router-link>
        <a
         href="https://malling.no/forbehold"
         target="_blank"
          class="footer__content-link footer__content-link-right"
          >{{ disclaimer('title') }}
      </a>
        <span class="footer__content-text"
          >Dette digitale prospektet er utviklet i samarbeid mellom Storebrand og Malling & Co
          Markets.</span
        >
      </div>

      <div class="footer__content-bottom-line">
        <span>
          {{ $t('pageMadeBy') }}
          <a
            id="https://www.kvass.no"
            :href="`https://www.kvass.no?ref=${currentUrl}`"
            target="_blank"
            >Kvass</a
          >
        </span>
        <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'compact'].includes(value)
      },
    },
    cookieManager: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },

    currentUrl() {
      return window.location.href
    },

    logo() {
      //return logo based on primary-color lightness

      if (!this.item.customFields) return

      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (this.item.customFields['footer-logo']) return this.item.customFields['footer-logo'].url
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted

      return API.logo
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    disclaimer(type) {
      if (!this.item.posts) return ''

      const disclaimer = this.item.posts.find(
        p => p.customFieldsConfig[0].source === 'lysakertorg-disclaimer',
      )
      if (!disclaimer) return ''

      return disclaimer[type]
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },

  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: var(--background);
  color: var(--black);
  padding-block: 1.25rem 2.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  &__content {
    border-top: 1px solid rgba(112, 112, 112, 0.25);

    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 1140px;
    margin: 0 auto;

    padding: 5px 10px;
    padding-top: calc(30px - 1rem);
    &-link {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji';
      font-weight: 400;

      &-left {
      }
      &-right {
      }
    }

    &-text {
      text-align: center;
      font-weight: 400;
    }

    &-bottom-line {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', 'Noto Color Emoji';

    &[id='https://www.kvass.no'] {
      position: relative;
      font-family: var(--primary-font);
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        width: 100%;
        height: 2px;
        background-color: var(--primary);
      }
    }
  }
}
</style>
