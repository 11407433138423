import { filter, transformContent } from '@/utils'
export default function() {
  return [
    {
      component: 'Banner',
      data: {
        backgroundImage: this.$path('item.media.cover.0.url'),
      },
      props: {
        class: 'project-page__banner',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0rem',
              class: 'project-page__layout project-page__banner--darken',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: this.$path('item.customFields.blackbox'),
                  props: {
                    class: 'project-page__blackbox',
                  },
                  data: {
                    content: transformContent(this.$path('item.customFields.blackbox')),
                  },
                },
                ...(this.$path('item.customFields.banner-content') || []).map(item => {
                  return {
                    component: 'Text',
                    props: {
                      textAlign: 'center',
                      class: 'project-page__content',
                    },
                    data: {
                      content: item,
                    },
                  }
                }),

                {
                  component: 'Flex',
                  condition: () => {
                    if (!this.$path('item.customFields.banner-button')) return false
                    return this.$path('item.customFields.banner-button').filter(f => {
                      if (!(f.link && f.label)) return false
                      return true
                    }).length
                  },
                  props: {
                    gap: '0.5rem',
                    class: 'project-page__button-layout',
                  },
                  blocks: {
                    default: (this.$path('item.customFields.banner-button') || []).map(item => {
                      return {
                        component: 'Text',
                        props: {
                          class: `project-page__button project-page__button--${item.theme}`,
                        },
                        data: {
                          content: `<span><a target=\"_self\" data-cta=\"primary\" href=\"${item.link}\">${item.label}</a></span>`,
                        },
                      }
                    }),
                  },
                },
              ].filter(f => filter(f)),
            },
          },
        ],
      },
    },
  ]
}
